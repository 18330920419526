import React from "react"
import PractitionerHero from "../components/PractitionerHero"
import Services from "../components/AvailableServices"
import BookNow from "../components/BookNow"
import ImageCarousel from "../components/Carousel"
import OurReviewCard from "../components/OurReviewCard"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import styled from "styled-components"

const Practitioner = ({ children, data }) => {
  const Bold = ({ children }) => <span className="bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }
  return (
    <Container>
      <SEO
        title={
          data.practitioner.seoTitle
            ? data.practitioner.seoTitle.seoTitle
            : `${data.practitioner.name} Bristol - Massages Bristol`
        }
        description={
          data.practitioner.seoDescription
            ? data.practitioner.seoDescription.seoDescription
            : ""
        }
      />
      <Header />
      <PractitionerHero
        name={data.practitioner.name}
        description={data.practitioner.description.json}
        topImage1={
          data.practitioner.topImage1 ? data.practitioner.topImage1.fluid : null
        }
        topImage2={
          data.practitioner.topImage2 ? data.practitioner.topImage2.fluid : null
        }
        address={data.practitioner.address}
        facebook={data.practitioner.facebook}
        website={data.practitioner.website}
      />
      <div className="content-body">
        <div className="flex-content">
          <div className="content-left">
            {data.practitioner.services ? (
              <Services services={data.practitioner.services} />
            ) : null}
            {data.ourReview ? (
              <OurReviewCard
                review={data.ourReview}
                name={data.practitioner.name}
              />
            ) : null}
            {data.practitioner.images ? (
              <ImageCarousel images={data.practitioner.images} />
            ) : null}
          </div>
          <div className="content-right">
            <BookNow
              name={data.practitioner.name}
              link={
                data.practitioner.bookNowButton ?? data.practitioner.website
              }
            />
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  )
}

export default Practitioner
export const pageQuery = graphql`
  query contentfulPractitionersBySlug($slug: String!) {
    ourReview: contentfulReview(slug: { eq: $slug }, ourReview: { eq: true }) {
      name
      description {
        description
      }
      rating
    }
    practitioner: contentfulPractitioners(slug: { eq: $slug }) {
      slug
      name
      address
      seoTitle {
        seoTitle
      }
      seoDescription {
        seoDescription
      }
      images {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      facebook
      bookNowButton
      topImage1 {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      topImage2 {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      website
      description {
        json
      }
      services {
        massageTypes {
          name
          description {
            description
          }
        }
        price
      }
    }
  }
`

const Container = styled.div`
  .content-body {
      padding:0 0 3em 7em;
      max-width: 1500px;
      margin: 0 auto;
  }
  .content-left {
      width:70%;
      padding:0 1em;
  }
  .content-right {
    width:30%;
    padding-top:1em;
}
  .flex-content {
    display: flex;
  }

  @media only screen and (max-width: 920px) {
    text-align: center;
    .content-body {
      padding:0em;
    }
    .content-right {
      width: 100%;
      padding: 1em 0;
    }
    .content-left {
      width: 100%;
      padding:0;
    }
    .flex-content {
      flex-direction:column;
    }
    p, h1, h3 {
      padding: 0 10px;
      margin-bottom: 0.5rem;
    }
`
