import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const OurReviewCard = ({ review, name }) => {
  const [fill] = useState(new Array(review.rating).fill(0))
  const [outline] = useState(new Array(5 - review.rating).fill(0))
  return (
    <Container>
      <h1>Our Review of {name}</h1>
      <div className="card-bg">
        <div className="left-side">
          <p>
            <i>"{review.description.description}"</i>
          </p>
          <div className="card-bottom">
            <span className="rating-title">Our Rating</span>
            {fill.map((item, index) => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 24 24"
                key={index}
              >
                <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
              </svg>
            ))}
            {outline.map((item, index) => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 24 24"
                key={index}
              >
                <path d="M12 5.173l2.335 4.817 5.305.732-3.861 3.71.942 5.27-4.721-2.524-4.721 2.525.942-5.27-3.861-3.71 5.305-.733 2.335-4.817zm0-4.586l-3.668 7.568-8.332 1.151 6.064 5.828-1.48 8.279 7.416-3.967 7.416 3.966-1.48-8.279 6.064-5.827-8.332-1.15-3.668-7.569z" />
              </svg>
            ))}
          </div>
        </div>
        <div className="right-side"></div>
      </div>
    </Container>
  )
}

export default OurReviewCard

const Container = styled.div`
  padding: 1em 0;
  h1 {
    font-size: 30px;
  }
  .card-bg {
    background: #feede2;
    border-radius: 10px;
    padding: 2.5em;
    display: flex;
    margin: 1em 0;
    position: relative;
  }
  .left-side {
    margin-right: 3em;
  }
  h3 {
    color: #1d2b3f;
    font-family: "Vidaloka", serif;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 36px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .card-img {
    width: 400px;
    height: 250px;
    margin-top: 2em;
  }
  .logo {
    width: 150px;
    height: 150px;
    position: absolute !important;
    top: 0;
    right: 1.25em;
  }
  .location {
    margin: 1em 0;
    font-size: 20px;
    color: #1d2b3f;
    text-transform: capitalize;
    line-height: 1.6em;
    display: flex;
    span {
      padding-left: 0.25em;
    }
    svg {
      transform: translateY(9px);
    }
  }
  p {
    color: #454545;
    font-family: "Karla";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 1em;
    max-width: 1000px;
  }
  svg {
    fill: #1d2b3f;
  }
  .card-bottom {
    width: auto;
    display: flex;
    align-items: center;
    svg {
      margin: 0 0.25em;
    }
  }
  .rating-title {
    color: #1d2b3f;
    font-family: "Vidaloka", serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 1em;
    font-weight: 400;
    text-align: left;
  }
  .btn {
    margin-top: 1.5em;
    margin-left: 4em;
    margin-bottom: 1.25em;
    bottom: 0;
    width: fit-content;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    border-radius: 100px;
    background: #1d2b3f;
    transition: all 0.5s;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 14px 12px;
    &:hover {
      filter: brightness(105%);
    }
    a {
      font-weight: 400;
      padding: 4px 10px;
      padding: 14px 12px;
    }
  }
  @media only screen and (max-width: 1100px) {
    .card-img {
      width: 340px;
      height: 200px;
    }
    .logo {
      width: 120px;
      height: 120px;
    }
    .left-side {
      margin-right: 1em;
    }
    .btn {
      margin-left: 2em;
    }
    .location {
      font-size: 18px;
    }
    .rating-title {
      font-size: 16px;
    }
    svg {
      margin: 0 0.2em !important;
    }
  }
  @media only screen and (max-width: 600px) {
    .card-bg {
      background: #feede2;
      display: flex;
      margin: 1em 0;
      position: relative;
      border-radius: 0;
      padding: 2em 0;
    }
    .card-bottom {
      width: 280px;
      margin: 0 auto;
    }
    .rating-title {
      font-size: 22px;
    }
    .left-side {
      width: 100%;
    }
    h1 {
      font-size: 22px;
      margin: 0;
    }
    .card-bg {
      margin: 0;
    }
  }
`
