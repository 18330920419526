import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Carousel } from "react-bootstrap"
import bootstrap from "!raw-loader!bootstrap/dist/css/bootstrap.min.css"
import { Helmet } from "react-helmet"

const ImageCarousel = ({ images }) => {
  const carouselItems = images.map(image => (
    <Carousel.Item interval={1000}>
      <div className="img-container">
        <Img fluid={image.fluid} className="img" />
      </div>
    </Carousel.Item>
  ))
  return (
    <Container>
      <Helmet>
        <style>{bootstrap}</style>
      </Helmet>
      <Carousel>{carouselItems}</Carousel>
    </Container>
  )
}

export default ImageCarousel

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  font-family: "Vidaloka", serif;
  color: #feede2;
  // .carousel {
  //     height: 500px;
  // }
  .img-container {
    height: 500px;
  }
  @media only screen and (max-width: 920px) {
    .img-container {
      height: 300px;
    }
  }
  @media only screen and (max-width: 500px) {
    .img-container {
      height: 200px;
    }
  }
  a {
    color: inherit !important;
  }
`
