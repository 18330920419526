import React from "react"
import styled from "styled-components"

const Services = ({ services }) => {
  const serviceItems = services.map(service => (
    <div className="service">
      <div className="price">From £ {service.price.toFixed(2)}</div>
      <h3 className="service-name">{service.massageTypes.name}</h3>
      <p className="service-description">
        {service.massageTypes.description.description}
      </p>
      <hr />
    </div>
  ))
  return (
    <Container>
      <h1 className="title">Available Services</h1>
      <div className="services">{serviceItems}</div>
    </Container>
  )
}

export default Services

const Container = styled.div`
  padding: 1em 0 0 0;
  position: relative;
  width: 100%;
  margin: auto;
  text-align: left;
  font-family: "Vidaloka", serif;
  h1 {
    font-weight: 200;
    &.title {
      margin-bottom: 1em;
    }
  }
  h3 {
    font-weight: bold;
    font-size: 20px;
  }
  .services {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
  .service {
    width: 100%;
    padding-right: 1em;
    line-height: 1.7;
  }
  .service-description {
    margin-top: 1.5em;
  }
  .price {
    padding-right: 1em;
    position: absolute;
    right: 0;
    font-weight: bold;
    font-size: 20px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 13px;
    .price {
      font-size: 14px;
    }
    .service {
      padding-right: 0;
    }
    .price {
      font-size: 14px;
    }
    h1 {
      font-size: 22px !important;
      margin-top: 0;
      line-height: inherit;
    }
    h3 {
      font-weight: bold;
      font-size: 15px;
      line-height: inherit;
    }
  }
`
