import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { FaMapMarkerAlt, FaFacebook, FaGlobe } from "react-icons/fa"

const PractitionerHero = ({
  name,
  description,
  address,
  website,
  topImage1,
  topImage2,
  facebook,
}) => {
  const Bold = ({ children }) => <span className="bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }

  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "hero-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "hero-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      <div className={`bg-color`} />
      <div className="dotted-pattern" />
      <div className="flex-box">
        <div className="hero-content">
          <h1>{name}</h1>
          <p className="address">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={"https://maps.google.com/?q=" + address}
            >
              <FaMapMarkerAlt size={15} /> {address}
            </a>
          </p>
          <div class="description">
            {documentToReactComponents(description, options)}
          </div>
          {facebook || website ? (
            <div class="flex-btns">
              {website ? (
                <a
                  rel="noopener noreferrer"
                  href={website}
                  target="_blank"
                  class="btn"
                >
                  <FaGlobe size={13} /> Visit Website
                </a>
              ) : null}
              <a
                rel="noopener noreferrer"
                href={facebook}
                class="btn fb"
                target="_blank"
              >
                <FaFacebook size={13} /> View Facebook
              </a>
            </div>
          ) : null}
        </div>
        <div className="imgs">
          <Img
            fluid={topImage1 ?? data.img1.childImageSharp.fluid}
            className="img1"
          />
          <Img
            fluid={topImage2 ?? data.img2.childImageSharp.fluid}
            className="img2"
          />
        </div>
      </div>
      <div className="leaf-white" />
      <div className="leaf-navy" />
    </Container>
  )
}

export default PractitionerHero

const Container = styled.div`
  padding: 5em 1em 2.5em 0;
  position: relative;
  max-width: 1500px;
  margin: auto;
  display: flex;
  align-items: center;

  .description {
    padding-top: 20px;
  }
  .hero-content {
    padding-left: 7em;
    max-width: 600px;
    min-height: 370px;
    padding-bottom: 40px;
    text-align: left;
  }
  .flex-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  h1 {
    font-size: 50px;
    margin-bottom: 0.5em;
    line-height: 1em;
    font-weight: 400;
    font-family: "Vidaloka", serif;
    color: #1d2b3f;
  }
  h2 {
    font-size: 30px;
    margin-bottom: 0.8em;
    line-height: 1.3em;
    font-weight: 400;
    max-width: 700px;
    font-family: "Vidaloka", serif;
    color: #1d2b3f;
  }
  p {
    max-width: 700px;
    color: #000000;
    font-family: "Karla";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
  }
  .dotted-pattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -3;
    background-image: url(${require("../images/dotted-pattern.svg")});
  }
  .bg-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -3;
    background: linear-gradient(90deg, #feede2 0%, #f7e4bf 100%);
  }
  .img1 {
    position: relative;
    width: 400px;
    right: 0;
    height: 250px;
    z-index: -1;
  }
  .img2 {
    width: 420px;
    height: 300px;
    position: relative;
    right: 27em;
    bottom: 2em;
    z-index: -2;
    transform: translateX(14em);
  }
  .leaf-white {
    position: absolute;
    width: 170px;
    height: 180px;
    top: 14em;
    right: 6em;
    background-position: center;
    background-size: cover;
    transform: rotate(80deg);
    background-image: url(${require("../images/leaf-white.svg")});
  }
  .leaf-navy {
    position: absolute;
    width: 149px;
    height: 165px;
    bottom: -2em;
    left: 1em;
    z-index: 2;
    transform: rotate(-80deg);
    background-image: url(${require("../images/leaf-navy.svg")});
  }
  @media only screen and (max-width: 1240px) {
    padding-top: 8em;
    .img1,
    .img2 {
      display: none;
    }
  }
  @media only screen and (max-width: 920px) {
    .leaf-white {
      width: 90px;
      height: 100px;
      top: 4.5em;
      right: 0.5em;
    }
    .leaf-navy {
      width: 90px;
      height: 100px;
      background-image: url(${require("../images/leaf-navy-small.svg")});
    }
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
      font-weight: 700;
    }
  }
  @media only screen and (max-width: 600px) {
    padding-top: 7em;
    text-align: left;
    line-height: 1.2;
    .bg-color {
      backround-color: #feede2;
    }
    .address {
      font-size: 20px;
      margin-bottom: 30px;
    }
    h1 {
      max-width: 270px;
      line-height: 1.4em;
    }
    .hero-content {
      padding-left: 0.5em;
    }
    .leaf-navy {
      display: none;
    }
    .description {
      padding-top: 0;
    }
    .dotted-pattern {
      display: none;
    }
    .leaf-white {
      display: none;
    }
    .hero-content {
      font-size: 30px;
    }
    .flex-btns {
      display: block !important;
      padding-top: 0 !important;
    }
    .btn {
      font-size: 18px !important;
      margin-top: 20px;
    }
    p {
      font-size: 18px;
      font-weight: normal;
      text-align: left;
      line-height: 20px;
    }
  }
  .flex-btns {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 340px;
    align-items: center;
  }
  .btn {
    min-width: unset;
    padding: 12px;
    border: none;
    color: #fff !important;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    border-radius: 100px;
    background: #1d2b3f;
    transition: all 0.5s;
    cursor: pointer;
    text-transform: uppercase;
    color: #fff;
    border: 2px solid #1d2b3f;
    &:hover {
      filter: brightness(115%);
    }
    &.fb {
      background: none;
      color: #1d2b3f !important;
      font-weight: bold;
      &:hover {
        filter: brightness(200%) !important;
      }
    }
  }
  .hero-content a {
    color: #000;
  }
`
