import React from "react"
import styled from "styled-components"

const bookNow = ({ name, link }) => {
  return (
    <Container>
      <div className="surround">
        <h1 className="title">Interested in booking a massage with {name}</h1>
        <div class="bottom-btn">
          <a rel="noopener noreferrer" href={link} class="btn" target="_blank">
            Book Now
          </a>
        </div>
      </div>
    </Container>
  )
}

export default bookNow

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  font-family: "Vidaloka", serif;
  color: #feede2;
  .surround {
    background-color: #1d2b3f;
    border: 2px solid #1d2b3f;
    border-radius: 10px;
    margin: 0 auto;
    @media only screen and (max-width: 500px) {
      border: none;
      border-radius: 0;
    }
  }
  .title {
    width: 80%;
    margin: 0 auto;
    padding: 1em 0 2em 0;
    text-align: center;
  }
  h1 {
    font-weight: 200;
  }
  .bottom-btn {
    position: absolute;
    bottom: 1.5em;
    width: 100%;
    text-align: center;
  }
  .btn {
    display: inline-block;
    min-width: unset;
    padding: 12px 20px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    border-radius: 100px;
    background: #feede2;
    transition: all 0.5s;
    cursor: pointer;
    text-transform: uppercase;
    color: #1d2b3f;
    &:hover {
      filter: brightness(115%);
    }
  }
  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 35px;
    }
    .bottom-btn {
      bottom: 0.5em;
    }
  }
`
